// div加入4个边角
import _ from 'lodash'
import Vue from 'vue'

Vue.directive('corner-4', {
    inserted(el: HTMLDivElement) {

    },
    bind: function (el, binding, vnode) {
        el.style.position = 'relative'

        let left_top = document.createElement('span')
        let right_top = document.createElement('span')
        let left_bottom = document.createElement('span')
        let right_bottom = document.createElement('span')

        left_top.style.left = '0'
        left_top.style.top = '0'

        right_top.style.right = '0'
        right_top.style.top = '0'

        left_bottom.style.left = '0'
        left_bottom.style.bottom = '0'

        right_bottom.style.right = '0'
        right_bottom.style.bottom = '0'


        _.each([
            x => left_top.style.borderLeft = x,
            x => left_top.style.borderTop = x,

            x => right_top.style.borderRight = x,
            x => right_top.style.borderTop = x,

            x => left_bottom.style.borderLeft = x,
            x => left_bottom.style.borderBottom = x,

            x => right_bottom.style.borderRight = x,
            x => right_bottom.style.borderBottom = x,
        ], fn => {
            fn('solid 2px')
        })

        _.each([left_top, right_top, left_bottom, right_bottom], corner => {
            corner.style.position = 'absolute'
            corner.style.width = '13px'
            corner.style.height = '13px'
            el.appendChild(corner)
        });

        let updateBorderColor = (color) => {
            _.each([
                left_top,
                right_top,
                left_bottom,
                right_bottom
            ], el => {
                el.style.borderColor = color
            })
        }

        (el as any).__disposeCorners = () => {
            _.each([left_top, right_top, left_bottom, right_bottom], corner => {
                el.removeChild(corner)
            });
        };
        (el as any).__updateBorderColor = updateBorderColor;

        let color = binding.value['border-color'];
        updateBorderColor(color)
    },
    unbind(el) {
        const disp = (el as any).__disposeCorners
        if (disp) {
            disp()
            delete (el as any).__disposeCorners
        }
    }
})
