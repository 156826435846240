/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

// 设备总览
import axios from "../axios"
import {
    IGetDeviceListData,
    IGetDeviceListParams,
    IGetSensorBatteryData,
    IGetSensorBatteryParams,
    IScreenTableData,
    IUploadMappingFileParams
} from "./overview.types";

/**
 * 根据区域获取设备分页列表
 */
export function getDeviceList(data: IGetDeviceListParams) {
    return axios<IGetDeviceListData>({
        url: "/overview/equip/findPage",
        method: "post",
        data,
    });
}

/**
 * 查询振动通道
 */
export function getScreenTableData(params: any) {
    return axios<IScreenTableData>({
        url: "/overview/equip/component/list",
        method: "get",
        params,
    });
}

/**
 * 配置管理-根据传感器获取
 */
export function getOverViewSensorInfo(params) {
    return axios({
        url: "/overview/equip/component/list",
        method: "get",
        params,
    });
}

/**
 * 获取传感器信息
 */
export function getSensorData(params: IGetSensorBatteryParams) {
    return axios<IGetSensorBatteryData>({
        url: '/overview/data',
        method: 'get',
        params,
    })
}
