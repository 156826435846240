/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { goToLogin } from '@/utils/auth';
import axios from '../axios'

import { IAutoLoginParams, ILoginData, ILoginParams } from './login.types';

import { baseUrl } from '@/utils/global'

export function buildCaptchaUrl(flag: string) {
    return baseUrl + '/captcha?flag=' + flag
}
export function login(params: ILoginParams) {
    return axios<ILoginData>({
        url: 'login',
        method: 'post',
        data: params,
        notRequireLogin: true,
    })
}

export function logout(vm: Vue) {
    return axios({
        method: 'get',
        url: 'logout'
    }).finally(() => {
        goToLogin(vm.$router)
    })
}

export function autoLogin(param: IAutoLoginParams) {
    return axios<ILoginData>({
        url: 'autoLogin',
        method: 'post',
        data: param,
        notRequireLogin: true,
    })
}