/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import axios from "../axios";
import {
    ICopyFaultSettingBySensorIdParams,
    IDiagnosisEquipmentGatherParams,
    IDiagnosisEquipmentHistoryData,
    IDiagnosisEquipmentHistoryParams,
    IDiagnosisGetWaveIdItem,
    IDiagnosisListData,
    IDiagnosisListParams,
    IEditFaultSettingParams,
    IFaultListPageData,
    IFaultListPageParams,
    IFaultSetting,
    IGetHighMappingParams,
    IStatisticsFaultCheckPasswordParams,
    IStatisticsFaultEditSuggestParams,
    IStatisticsFaultGetExtendData,
    IStatisticsFaultGetExtendParams,
    IStatisticsFaultGetHistoryData,
    IStatisticsFaultInspectionWaveDiagnosisData,
    IStatisticsFaultInspectionWaveDiagnosisParams,
    IStatisticsFaultListData,
    IStatisticsFaultListParams,
    IStatisticsFaultThresholdCheckData,
    IStatisticsFaultThresholdCheckParams,
    IStatisticsFaultWaveDiagnosisData,
    IStatisticsFaultWaveDiagnosisParams
} from "./fault.types";

/**
 * 故障诊断列表
 */
export function faultListPage(data: IFaultListPageParams) {
    return axios<IFaultListPageData>({
        url: "/fault/findPage",
        method: "post",
        data: data,
    });
}

/**
 * 编辑提交
 */
export function fetchSubmit(data: any) {
    return axios({
        url: "/fault/equipment/gather",
        method: "post",
        data: data,
    });
}

/**
 * 编辑历史
 */
export function fetchHistory(data: any) {
    return axios({
        url: "/fault/equipment/history",
        method: "get",
        params: data,
    });
}

/**
 * 根据设备获取最高报警测点
 */
export function getHighMapping(params: IGetHighMappingParams) {
    return axios({
        url: "/fault/get/high/mapping",
        method: "get",
        params,
    });
}

// 故障诊断 新!!

/**
 * 诊断列表
 */
export function diagnosisList(params: IDiagnosisListParams) {
    return axios<IDiagnosisListData>({
        method: 'post',
        url: '/fault/diagnosis/findPage',
        data: params,
    })
}

/**
 * 维保建议 历史
 */
export function diagnosisEquipmentHistory(params: IDiagnosisEquipmentHistoryParams) {
    return axios<IDiagnosisEquipmentHistoryData>({
        method: 'get',
        url: '/fault/diagnosis/equipment/history',
        params,
    })
}

/**
 * 设备汇总建议新增
 */
export function diagnosisEquipmentGather(params: IDiagnosisEquipmentGatherParams) {
    return axios({
        method: 'post',
        url: '/fault/diagnosis/equipment/gather',
        data: params,
    })
}

/**
 * 设备汇总建议新增
 */
export function diagnosisGetWaveId(params: {
    faultSn: string
}) {
    return axios<IDiagnosisGetWaveIdItem>({
        method: 'get',
        url: '/fault/diagnosis/getWaveId',
        params,
    })
}

/**
 * 故障诊断删除
 */
export function diagnosisDelFault(faultSn: string) {
    return axios({
        method: 'get',
        url: `/fault/diagnosis/del/${faultSn}`,
    })
}

/**
 * 获取传感器故障诊断默认配置参数
 */
export function queryFaultSettingBySensorId(sensorId: string) {
    return axios<IFaultSetting>({
        method: 'get',
        url: `/fault/setting/queryFaultSettingBySensorId/${sensorId}`
    })
}

/**
 * 传感器故障诊断配置参数-编辑
 */
export function editFaultSetting(params: IEditFaultSettingParams) {
    return axios({
        method: 'post',
        url: '/fault/setting/edit',
        data: params,
    })
}

/**
 * 传感器故障诊断配置参数-复制到指定传感器
 */
export function copyFaultSettingBySensorId(params: ICopyFaultSettingBySensorIdParams) {
    return axios({
        method: 'post',
        url: '/fault/setting/copyFaultSettingBySensorId',
        data: params,
    })
}

//#region 
/**
 * 编辑故障描述
 */
export function statisticsFaultEditSuggest(params: IStatisticsFaultEditSuggestParams) {
    return axios({
        method: 'post',
        url: '/fault/statistics/editSuggest',
        data: params,
    })
}

/**
 * 分页查询
 */
export function statisticsFaultList(params: IStatisticsFaultListParams) {
    return axios<IStatisticsFaultListData>({
        method: 'get',
        url: '/fault/statistics/findPage',
        params
    })
}

/**
 * 查询历史故障描述
 */
export function statisticsFaultGetHistory(id: number) {
    return axios<IStatisticsFaultGetHistoryData>({
        method: 'get',
        url: `/fault/statistics/getHistory/${id}`,
    })
}

/**
 * 查询今日诊断统计
 */
export function statisticsFaultGetTodayStatistics(equipmentId: number) {
    return axios({
        method: 'get',
        url: `/fault/statistics/getTodayStatistics/${equipmentId}`,
    })
}

/**
 * 查询阈值确认
 */
export function statisticsFaultThresholdCheck(params: IStatisticsFaultThresholdCheckParams) {
    return axios<IStatisticsFaultThresholdCheckData>({
        method: 'get',
        url: '/fault/statistics/getThresholdCheck',
        params,
    })
}

/**
 * 波形诊断
 */
export function statisticsFaultWaveDiagnosis(params: IStatisticsFaultWaveDiagnosisParams) {
    return axios<IStatisticsFaultWaveDiagnosisData>({
        method: 'post',
        url: '/fault/statistics/waveDiagnosis',
        data: params
    })
}

/**
 * 校验密码
 */
export function statisticsFaultCheckPassword(params: IStatisticsFaultCheckPasswordParams) {
    return axios({
        method: 'post',
        url: '/fault/statistics/checkPassword',
        data: params
    })
}

/**
 * 获取设备部件
 */
export function statisticsFaultGetExtend(params: IStatisticsFaultGetExtendParams) {
    return axios<IStatisticsFaultGetExtendData>({
        method: 'get',
        url: '/fault/statistics/getExtend',
        params
    })
}

/**
 * 波形诊断-点检
 */
export function statisticsFaultInspectionWaveDiagnosis(params: IStatisticsFaultInspectionWaveDiagnosisParams) {
    return axios<IStatisticsFaultInspectionWaveDiagnosisData>({
        method: 'post',
        url: '/fault/statistics/inspectionWaveDiagnosis',
        data: params,
    })
}

//#endregion
