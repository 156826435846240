/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import Vue from "vue";
import App from "./App.vue";
import { makeStore } from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/utils/rem";
import "./directive/index";
import "./registerComponents"

import api from "./api";
import { makeI18n } from "./i18n";
import { install as globalInstall } from "./plugins/global";
import "./sass/index.scss";
import { transMixin } from "./mixin/trans";
import { makeRouter } from "./router";
import { gRouter, gStore, gVm } from "./global";
import { install as permissionInstall } from './plugins/permission'
import { utilMixin } from "./mixin/util";
import "./config/fa"
import { themeInit } from "./utils/theme";

Vue.config.productionTip = false;

Vue.use(ElementUI);

Vue.use(api);
Vue.use(globalInstall);

Vue.mixin(transMixin)
Vue.mixin(utilMixin)

const i18n = makeI18n()
const store = makeStore(i18n)
const router = makeRouter(store)
// makePermission(store)
Vue.use(permissionInstall, store)

gStore.value = store
gRouter.value = router
themeInit()

const app = new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");

document.body.dataset['locale'] = app.$i18n.locale

app.$watch('$i18n.locale', (locale) => {
    // 留点时间给 localeStorage
    setTimeout(() => {
        window.location.reload()
    }, 20)
})
gVm.value = app
// 监听卸载操作
// window.addEventListener("unmount", function () {
//   app.$destroy();
// });
