/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { Vue, Component, Provide, } from 'vue-property-decorator'
import MainTitle from '@/components/MainTitle/MainTitle.vue'
import MainMenu from '@/components/MainMenu/MainMenu.vue'
import { makeMainMenuItems } from './constants/menu'
import { Getter, State } from 'vuex-class'
import { IUserMenu } from './api/moudules/system.types'
import { IMenuItem } from './components/MainMenu/MainMenuImpl'
import BrowerTitle from './components/BrowerTitle.vue'
import EigenvalueShow from './popup/EigenvalueShow.vue'
import { IEigenvalueInfo } from './api/moudules/wave.types'

function filterPermissionMenus(menus: IMenuItem[], permissionSet: Set<string>) {
    return _.flatMap(menus, menu => {
        if (menu.permission && !permissionSet.has(menu.permission)) {
            return []
        }
        const children = menu.children
        if (_.isEmpty(children)) {
            return [menu]
        }
        const childrenRest = _.flatMap(menu.children, c => {
            if (_.isNil(c.permission)) {
                return [c]
            }
            if (permissionSet.has(c.permission)) {
                return [c]
            } else {
                return []
            }
        })
        return [{
            ...menu,
            children: childrenRest,
        }]
    })
}

@Component({
    name: 'App',
    components: {
        MainMenu,
        MainTitle,
        BrowerTitle,
        EigenvalueShow,
    }
})
export class AppImpl extends Vue {
    get ShowLayoutComponents() {
        const {
            name,
            meta,
        } = this.$route
        if (_.isNil(name)) {
            return false
        }
        if (meta?.noMainLayoutComponent) {
            return false
        }
        return true
    }

    @State
    menus: IUserMenu[]

    @Getter
    isSuperAdmin: boolean

    @Getter
    menuPermissionSet: Set<string>

    @State
    loading: boolean

    filterMenus(menus: IMenuItem[]) {
        if (this.isSuperAdmin) {
            return _.filter(menus, menu => {
                return menu.isSuperAdminMenu
            })
        }
        const menusRest = _.filter(menus, menu => {
            return !menu.isSuperAdminMenu
        })
        return filterPermissionMenus(menusRest, this.menuPermissionSet)
    }

    get MainMenuItems() {
        let mainMenuItems = makeMainMenuItems(this)
        mainMenuItems = this.filterMenus(mainMenuItems)
        return _.map(mainMenuItems, menuItem => {
            // 1.收集所有的menu
            let routers: string[] = []
            if (menuItem.router) {
                routers.push(menuItem.router)
            }
            if (menuItem.children) {
                routers = [...routers, ..._.map(menuItem.children, c => c.router)]
            }
            const routerSet = new Set(routers)
            return {
                ...menuItem,
                isActive: _.some(this.$route.matched, x => routerSet.has(x.name) || routerSet.has(x.meta?.parentName))
            }
        })
    }
    get IsManagement() {
        const isConfigureManager = _.some(this.$route.matched, x => x.name === 'configureManagement')
        const isMenuManagement = _.includes(['customerManagement_menu'], this.$route.name)
        const isDeviceScreen = _.includes(['deviceScreen', 'deviceScreenEdit'], this.$route.name)
        return (isConfigureManager || isMenuManagement) && !isDeviceScreen
    }

    get IsWaveSpectrum() {
        const isWaveSpectrum = this.$route.name === 'waveSpectrum'
        return isWaveSpectrum
    }

    showEigenvalue = false
    onCloseEigenvalue() {
        this.showEigenvalue = false
    }
    eigenvalue: IEigenvalueInfo = {} as any

    @Provide('showEigenvalue')
    onShowEigenvalue(visible: boolean) {
        this.showEigenvalue = visible
    }
    @Provide('updateEigenvalue')
    onUpdateEigenvalue(value: IEigenvalueInfo) {
        this.eigenvalue = value
    }
}
