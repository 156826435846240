import { render, staticRenderFns } from "./TopMenuItem.vue?vue&type=template&id=4da73891&scoped=true&"
import script from "./TopMenuItem.vue?vue&type=script&setup=true&lang=ts&"
export * from "./TopMenuItem.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./TopMenuItem.vue?vue&type=style&index=0&id=4da73891&prod&lang=scss&scoped=true&"
import style1 from "./TopMenuItem.vue?vue&type=style&index=1&id=4da73891&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4da73891",
  null
  
)

export default component.exports