/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { E_AlarmType } from '@/api/enums'
import { E_AlarmAttachType, IAlarmDetail, IGetFaultanAlysisStatisticsData } from '@/api/moudules/alarm.types'
import { E_ModelPartial } from '@/api/moudules/model.types'
import { E_Axis } from '@/api/moudules/sensor.types'
import { IReferenceItem } from '@/components/FaultAnalysisDetail/FaultAnalysisReference/FaultAnalysisReferenceImpl'
import { E_FaultAlysisStatistics } from '@/types'
import { formatDateTime } from '@/utils/datetime'
import _ from 'lodash'

export function reformatSuggest(suggest: string, separator = '<br/>') {
    let parts = _.split(suggest, '\\n')
    parts = _.filter(parts, p => !_.isEmpty(p))
    return _.join(parts, separator)
}

export function collectionReferenceItems(data: IGetFaultanAlysisStatisticsData): IReferenceItem[] {
    data = data || {} as any
    const {
        componentType,
        bearingBreath, bearingPercentage,
        misaligned, misalignedPercentage,
        looseness, loosenessPercentage,
        imbalance, imbalancePercentage,
        unevenBreath, unevenBreathPercentage,
        resonance, resonancePercentage,
    } = data

    // 获取参考值
    let reference = [
        {
            kind: E_FaultAlysisStatistics.looseness,
            value: looseness,
            percentage: loosenessPercentage,
        }, {
            kind: E_FaultAlysisStatistics.bearingBreath,
            value: bearingBreath,
            percentage: bearingPercentage,
        }, {
            kind: E_FaultAlysisStatistics.misaligned,
            value: misaligned,
            percentage: misalignedPercentage,
        }, {
            kind: E_FaultAlysisStatistics.unevenBreath,
            value: unevenBreath,
            percentage: unevenBreathPercentage,
        }, {
            kind: E_FaultAlysisStatistics.imbalance,
            value: imbalance,
            percentage: imbalancePercentage,
        }, {
            kind: E_FaultAlysisStatistics.resonance,
            value: resonance,
            percentage: resonancePercentage,
        }]

    switch (componentType) {
        case E_ModelPartial.Fans:
            reference.push({
                kind: E_FaultAlysisStatistics.fanBreath,
                value: data.fanBreath,
                percentage: data.fanPercentage,
            })
            break;
        case E_ModelPartial.WaterPump:
            reference.push({
                kind: E_FaultAlysisStatistics.pumpBreath,
                value: data.pumpBreath,
                percentage: data.pumpPercentage,
            })
            break;
        case E_ModelPartial.Cogs:
            const {
                gearBBreath,
                gearBPercentage,
                gearFBreath,
                gearFPercentage,
                gearMBreath,
                gearMPercentage,
            } = data
            reference = [...reference, {
                kind: E_FaultAlysisStatistics.gearBBreath,
                value: gearBBreath,
                percentage: gearBPercentage,
            }, {
                kind: E_FaultAlysisStatistics.gearFBreath,
                value: gearFBreath,
                percentage: gearFPercentage,
            }, {
                kind: E_FaultAlysisStatistics.gearMBreath,
                value: gearMBreath,
                percentage: gearMPercentage,
            }]
            break;
    }
    return reference
}

function buildAlarmTooltip(axis: string, x: number, y: number, z: number, sep = ',') {
    if (!axis) return ''
    let parts = []
    if (axis.search('X') !== -1) {
        parts.push(`x: ${x}`)
    }
    if (axis.search('Y') !== -1) {
        parts.push(`y: ${y}`)
    }
    if (axis.search('Z') !== -1) {
        parts.push(`z: ${z}`)
    }
    return parts.join(sep)
}

interface IXYZThresold {
    x: number
    y: number
    z: number
}

function safeShowThresold(c: number, d: number) {
    let hasC = !_.isNil(c)
    let hasD = !_.isNil(d)
    if (!hasC && !hasD) {
        return
    }
    let values = [
        ...hasC ? [c] : [],
        ...hasD ? [d] : []
    ]
    return '(' + values.join(' , ') + ')'
}

function buildAlarmThresold(axis: string, c: IXYZThresold, d: IXYZThresold) {
    if (!axis) return ''
    let parts = []
    if (axis.search('X') !== -1) {
        let r = safeShowThresold(c.x, d.x)
        if (r) {
            parts.push('x:' + r)
        }
    }
    if (axis.search('Y') !== -1) {
        let r = safeShowThresold(c.y, d.y)
        if (r) {
            parts.push('y:' + r)
        }
    }
    if (axis.search('Z') !== -1) {
        let r = safeShowThresold(c.z, d.z)
        if (r) {
            parts.push('z:' + r)
        }
    }
    return parts.join('<br>')
}

export interface IAlaramValueCollect {
    alarmType: E_AlarmType
    alarmAxis: string
    battery: number
    kurtX: number
    kurtY: number
    kurtZ: number
    peakX: number
    peakY: number
    peakZ: number
    vx: number
    vy: number
    vz: number
    tem: number
}

export interface IAlaramValueCollectEx extends IAlaramValueCollect {
    /**
     * 报警时阈值，(温度/电压使用xcMax/xDMax)
     */
    xcMax: number
    xdMax: number
    ycMax: number
    ydMax: number
    zcMax: number
    zdMax: number
}

export function getAlaramValueDisplayEx(params: IAlaramValueCollectEx) {
    const {
        alarmAxis,
        alarmType,
        battery,
        kurtX,
        kurtY,
        kurtZ,
        peakX,
        peakY,
        peakZ,
        vx,
        vy,
        vz,
        tem,
        xcMax,
        xdMax,
        ycMax,
        ydMax,
        zcMax,
        zdMax,
    } = params
    let tooltip = ''
    let thresold = ''
    let cThresold = { x: xcMax, y: ycMax, z: zcMax }
    let dThresold = { x: xdMax, y: ydMax, z: zdMax }
    let br = '<br>'
    switch (alarmType) {
        case E_AlarmType.Vibration:
            tooltip = buildAlarmTooltip(alarmAxis, vx, vy, vz, br)
            thresold = buildAlarmThresold(alarmAxis, cThresold, dThresold)
            break
        case E_AlarmType.Steepness:
            tooltip = buildAlarmTooltip(alarmAxis, kurtX, kurtY, kurtZ, br)
            thresold = buildAlarmThresold(alarmAxis, cThresold, dThresold)
            break
        case E_AlarmType.Crest:
            tooltip = buildAlarmTooltip(alarmAxis, peakX, peakY, peakZ, br)
            thresold = buildAlarmThresold(alarmAxis, cThresold, dThresold)
            break
        case E_AlarmType.Temperature:
            tooltip = _.toString(tem)
            thresold = safeShowThresold(xcMax, xdMax)
            break
        case E_AlarmType.Voltage:
            tooltip = _.toString(battery)
            thresold = safeShowThresold(xcMax, xdMax)
            break
        case E_AlarmType.Offline:
            break
        case E_AlarmType.Electricity:
            // 暂时没有
            break
    }
    return {
        value: tooltip,
        thresold,
    }
}

export function getAlaramValueDisplay(params: IAlaramValueCollect) {
    const {
        alarmAxis,
        alarmType,
        battery,
        kurtX,
        kurtY,
        kurtZ,
        peakX,
        peakY,
        peakZ,
        vx,
        vy,
        vz,
        tem,
    } = params
    let tooltip = ''
    switch (alarmType) {
        case E_AlarmType.Vibration:
            tooltip = buildAlarmTooltip(alarmAxis, vx, vy, vz)
            break
        case E_AlarmType.Steepness:
            tooltip = buildAlarmTooltip(alarmAxis, kurtX, kurtY, kurtZ)
            break
        case E_AlarmType.Crest:
            tooltip = buildAlarmTooltip(alarmAxis, peakX, peakY, peakZ)
            break
        case E_AlarmType.Temperature:
            tooltip = _.toString(tem)
            break
        case E_AlarmType.Voltage:
            tooltip = _.toString(battery)
            break
        case E_AlarmType.Offline:
            break
        case E_AlarmType.Electricity:
            // 暂时没有
            break
    }
    return tooltip
}

export function exAlarmDetail(alarm: IAlarmDetail) {
    const {
        createTime,
        attachVOList,
    } = alarm
    const attachVOGroup = _.groupBy(attachVOList || [], item => {
        return item.attachType
    })
    let {
        value: alarmValue,
        thresold: alarmThresold,
    } = getAlaramValueDisplayEx(alarm)
    return {
        createTimeShow: formatDateTime(createTime),
        ...alarm,
        alarmValue,
        alarmThresold,
        audioList: attachVOGroup[E_AlarmAttachType.Audio] || [],
        imageList: attachVOGroup[E_AlarmAttachType.Image] || [],
    }
}

export type IAlarmDetailEx = ReturnType<typeof exAlarmDetail>