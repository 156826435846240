import { IPlatformCompanySettingData, PlatformImageKinds } from "@/api/moudules/platformCompany.types";

import LargeScreenLogo from "@/assets/image/screen/title1.png"
import LargeScreenName from "@/assets/image/screen/title2.png"
import LargeScreenName__en from "@/assets/image/screen/title2__en.png"

import DefaultLogo from '@/assets/image/common/head-logo.png'
import DefaultPlatform from '@/assets/image/common/head-name.png'
import DefaultLogoUrl__en from '@/assets/image/common/head-logo__en.png'
import DefaultPlatform__en from '@/assets/image/common/head-name__en.png'
import { buildFullFileUrl } from "@/api/moudules/file";

const defaultUrlMap: Record<PlatformImageKinds, { 'cn': string, 'en': string }> = Object.freeze({
    'largeScreenImg': {
        'cn': LargeScreenName,
        'en': LargeScreenName__en,
    },
    'largeScreenLogo': {
        'cn': LargeScreenLogo,
        'en': LargeScreenLogo,
    },
    'platform': {
        'cn': DefaultPlatform,
        'en': DefaultPlatform__en,
    },
    'logo': {
        'cn': DefaultLogo,
        'en': DefaultLogoUrl__en,
    },
    'weeklyCover': {
        'cn': '',
        'en': '',
    },
    'pageHeader': {
        'cn': '',
        'en': '',
    },
    'pageFooter': {
        'cn': '',
        'en': '',
    },
    'systemLogo': {
        'cn': '/jyx.jpg',
        'en': '/jyx.jpg',
    }
})

export function platformImageUrl(vm: Vue, kind: PlatformImageKinds) {
    let platformSetting: IPlatformCompanySettingData = vm.$store.state['platformSetting']
    let urlKey = kind + 'Url'
    if (platformSetting && platformSetting[urlKey]) {
        return buildFullFileUrl(platformSetting[urlKey])
    }
    const lang = vm.$i18n.locale
    return defaultUrlMap[kind][lang]
}
