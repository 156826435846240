/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import AccoutHeader from '../AccoutHeader/AccoutHeader.vue'
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch.vue'
import { Getter } from 'vuex-class'

import AlarmPopupList from '@/popup/AlarmPopupList/AlarmPopupList.vue'
import { isFailed } from '@/api/helper'
import { hasAlaramPopup, markHasAlaramPopup } from '@/popup/AlarmPopupList'
import CloseAlarmLamp from '@/views/configureManagement/alarmLampCreateEdit/popup/CloseAlarmLamp/CloseAlarmLamp.vue'
import TopMenu from '../TopMenu/TopMenu.vue';
import { IMenuItem } from '../MainMenu/MainMenuImpl'
import ThemeSwitch from '../ThemeSwitch.vue'
import { platformImageUrl } from '@/view_helper/platform'
import { autoLogin } from '@/utils/global'

@Component({
    name: 'MainTitle',
    components: {
        AccoutHeader,
        LanguageSwitch,
        AlarmPopupList,
        CloseAlarmLamp,
        TopMenu,
        ThemeSwitch,
    }
})
export class MainTitleImpl extends Vue {
    autoLogin = autoLogin


    @Prop({
        type: Array,
        default: () => []
    }) menus: IMenuItem[]

    @Getter
    isSuperAdmin: boolean

    get LogoUrl() {
        return platformImageUrl(this, 'logo')
    }

    get PlatformUrl() {
        return platformImageUrl(this, 'platform')
    }

    //#region 报警通知
    showAlarmPopupList = false
    alarmPopupListTotal = 0
    alarmPopupData = []

    fetchAlaramPopupList() {
        this.$api.alarm.popupList({
            pageNum: 1,
            pageSize: 10,
        }).then(res => {
            if (isFailed(res)) {
                return
            }
            const {
                total,
                records
            } = res.data
            if (total > 0) {
                this.alarmPopupListTotal = total
                this.alarmPopupData = records || []
                let notify = this.$notify({
                    title: this.$t('alarm.alarmTitle') as string,
                    message: this.$t('alarm.alarmTipTemplate', [total]) as string,
                    offset: 100,
                    duration: 0,
                    customClass: 'alarm-notify',
                    onClick: () => {
                        this.showAlarmPopupList = true
                        notify.close()
                    }
                })
            }
        })
    }
    onAlarmPopupClose() {
        this.showAlarmPopupList = false
    }
    //#endregion


    mounted() {
        if (!this.isSuperAdmin && !hasAlaramPopup()) {
            this.fetchAlaramPopupList()
            markHasAlaramPopup()
        }
    }
    //#region 
    showAlarmLamp = false
    onClickAlarmLamp() {
        this.showAlarmLamp = true
    }
    onAlarmLampClose() {
        this.showAlarmLamp = false
    }
    //#endregion
}
